/* Font imports and base styles */
@import url('https://fonts.googleapis.com/css2?family=Bentham&family=Bilbo+Swash+Caps&family=Cinzel+Decorative:wght@400;700;900&family=Dancing+Script:wght@400..700&family=Great+Vibes&family=Italiana&family=Markazi+Text:wght@400..700&family=Merienda:wght@300..900&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Oswald:wght@200..700&family=Prata&family=Questrial&display=swap');

/* Color variables */
:root {
  --primary-red: rgb(248, 40, 58);
  --dark-red: hsl(0, 0%, 0%);
  --black: #1a1a1a;
  --white: #ffffff;
  --light-gray: #f8f9fa;
}
.body{
  padding-top: 150px;
}
/* Sticky header wrapper */
.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
  width: 100%;
}

/* Top bar styles */
.top-bar {
  background-color: var(--primary-red);
  font-family: 'Montserrat', sans-serif;
  font-size: 0.9rem;
}

/* Main navigation styles */
.main-navbar {
  background-color: var(--white) !important;
  padding: 1rem 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Bentham', serif;
}

.main-navbar .navbar-brand img {
  transition: transform 0.3s ease;
}

.main-navbar .navbar-brand:hover img {
  transform: scale(1.05);
}

.main-navbar .nav-link {
  color: var(--black) !important;
  font-weight: 500;
  font-size: 1.1rem;
  margin: 0 0.5rem;
  padding: 0.5rem 1rem;
  position: relative;
  transition: color 0.3s ease;
}
.main-navbar .active{
  color:var(--primary-red);
  font-weight: 600;
}

.main-navbar .nav-link:hover {
  color: var(--primary-red) !important;
}


.main-navbar .nav-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--primary-red);
  transition: width 0.3s ease;
}

.main-navbar .nav-link:hover::after ,
.main-navbar .nav-link.active::after
{
  width: 100%;
}

.donate-btn {
  background-color: var(--primary-red);
  color: var(--white) !important;
  border-radius: 30px;
  padding: 0.5rem 1.5rem !important;
  margin-left: 1rem;
  font-weight: 600;
  transition: all 0.3s ease;
}

.donate-btn:hover {
  background-color: var(--dark-red);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(230, 57, 70, 0.3);
  color: var(--white) !important;
}

/* Responsive styles */
@media (max-width: 991.98px) {
  .main-navbar .navbar-collapse {
    background-color: var(--white);
    padding: 1rem;
    margin-top: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .main-navbar .nav-link {
    margin: 0.5rem 0;
    padding: 0.5rem 0;
  }
  
  .donate-btn {
    margin-left: 0;
    margin-top: 0.5rem;
    display: inline-block;
  }
}

/* Animation styles */
.fade-in {
  animation: fadeIn 0.5s ease-in;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}