.document-icon{
    transition: ease-in-out 1sec;
    cursor: pointer;
    
}
.document-icon::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--primary-red);
    transition: width 0.3s ease;
  }
.document-icon:hover{
    transform: scale(105%);
    padding: 20px;
    border:1px solid rgb(248, 40, 58);
}
.doc-text{
    
}
.document-icon:hover .doc-text{
    font-size: 20px;
    font-weight: 700;
    color: black !important;
}