/* Font imports */
@import url('https://fonts.googleapis.com/css2?family=Bentham&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --primary-red: rgb(248, 40, 58);
  --black: #000000;
  --white: #ffffff;
  --dark-gray: hsl(0, 2%, 22%);
}

.modern-footer {
  background-color: var(--black);
  color: var(--white);
  font-family: 'Montserrat', sans-serif;
}

.footer-title {
  font-family: 'Bentham', serif;
  font-size: 1.5rem;
  color: var(--white);
  position: relative;
  padding-bottom: 10px;
}

.footer-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 2px;
  background-color: var(--primary-red);
}

.quick-links li a {
  color: var(--white);
  text-decoration: none;
  transition: color 0.3s ease;
}

.quick-links li a:hover {
  color: var(--primary-red);
  padding-left: 5px;
}

.contact-info li {
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
}

.contact-info i {
  margin-top: 3px;
  margin-right: 10px;
}

.social-links a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: var(--dark-gray);
  color: var(--white);
  transition: all 0.3s ease;
}

.social-links a:hover {
  background-color: var(--primary-red);
  transform: translateY(-3px);
}

.newsletter-input {
  background-color: var(--dark-gray);
  border: 1px solid var(--dark-gray);
  color: var(--white);
}

.newsletter-input:focus {
  background-color: var(--dark-gray);
  color: var(--white);
  border-color: var(--primary-red);
  box-shadow: none;
}

.btn-subscribe {
  background-color: var(--primary-red);
  border: none;
  font-weight: 600;
}

.btn-subscribe:hover {
  background-color: #d00000;
}

.footer-bottom {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 10px 0;
}

/* Responsive adjustments */
@media (max-width: 1199.98px) {
  .footer-title {
    font-size: 1.2rem;
  }

  .quick-links li {
    margin-bottom: 10px;
  }
}

@media (max-width: 991.98px) {
  .footer-title {
    font-size: 1.4rem;
  }
}

@media (max-width: 767.98px) {
  .footer-title {
    font-size: 1.3rem;
    text-align: center;
    margin-top: 20px;
  }

  .quick-links {
    margin-bottom: 20px;
    text-align: center;
  }

  .quick-links li {
    margin-bottom: 10px;
    text-align: center;
  }

  .contact-info {
    text-align: center;
  }

  .contact-info li {
    justify-content: center;
  }

  .social-links {
    justify-content: center;
    margin-top: 20px;
  }

  .newsletter-input {
    width: 100%;
  }

  .btn-subscribe,
  .btn-outline-danger {
    width: 100%;
    margin-bottom: 10px;
  }

  .footer-bottom p {
    font-size: 0.9rem;
  }

  /* Fix for Logo and Text next to it on small screens */
  .d-flex.align-items-center {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }

  .d-flex.align-items-center img {
    margin-bottom: 10px;
  }

  .d-flex.align-items-center div {
    max-width: 100%;
  }
}

@media (max-width: 575.98px) {
  .footer-title {
    font-size: 1.2rem;
  }

  .social-links a {
    width: 30px;
    height: 30px;
  }
}
