/* General Styles */
.about-us-page {
    font-family: 'Arial', sans-serif;
    color: #333;
    overflow-x: hidden;
  }
  
  /* Hero Section - Fixed background image */
  .hero-section {
    height: 10vh;
   
    min-height: 200px;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .hero-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
 
  }
  
  .hero-content {
    position: relative;
    z-index: 1;
    text-align: center;
    color: white;
    padding: 0 20px;
  }
  
  .hero-content h1 {
    font-size: 4rem;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  /* Redesigned Design Section with Ovals */
  .design-section-wrapper {
    margin: 5rem 0;
    align-items: center;
  }
  
  .oval-image-container {
    position: relative;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .oval-parent {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .oval-shape {
    position: absolute;
    border-radius: 50%;
    background: linear-gradient(45deg, #d9534f, #f0ad4e);
    filter: blur(1px);
    opacity: 0.7;
  }
  
  .oval-1 {
    width: 300px;
    height: 400px;
    top: 0;
    left: 50px;
    animation: float 6s ease-in-out infinite;
  }
  
  .oval-2 {
    width: 350px;
    height: 350px;
    bottom: 0;
    right: 50px;
    animation: float 8s ease-in-out infinite reverse;
  }
  
  .design-image-container {
    position: relative;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    overflow: hidden;
    border: 5px solid white;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    z-index: 2;
  }
  
  .design-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
  }
  
  .design-content {
    padding: 2rem;
  }
  
  .design-title {
    font-size: 2.5rem;
    color: #d9534f;
    margin-bottom: 1.5rem;
    position: relative;
    display: inline-block;
  }
  
  .design-title::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 50px;
    height: 3px;
    background-color: #d9534f;
  }
  
  .design-text {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
  }
  
  /* Animation */
  @keyframes float {
    0% {
      transform: translateY(0) rotate(0deg);
    }
    50% {
      transform: translateY(-20px) rotate(5deg);
    }
    100% {
      transform: translateY(0) rotate(0deg);
    }
  }
  
  /* Responsive Adjustments */
  @media (max-width: 992px) {
    .design-section-wrapper {
      flex-direction: column;
    }
    
    .oval-image-container {
      height: 300px;
      margin-bottom: 2rem;
    }
    
    .design-content {
      text-align: center;
    }
    
    .design-title::after {
      left: 50%;
      transform: translateX(-50%);
    }
  }
  
  @media (max-width: 768px) {
    .hero-content h1 {
      font-size: 2.5rem;
    }
    
    .design-title {
      font-size: 2rem;
    }
    
    .oval-1 {
      width: 250px;
      height: 350px;
      left: 20px;
    }
    
    .oval-2 {
      width: 300px;
      height: 300px;
    }
    
    .design-image-container {
      width: 250px;
      height: 250px;
    }
  }
  
  @media (max-width: 576px) {
    .hero-section {
      height: 50vh;
      min-height: 300px;
    }
    
    .hero-content h1 {
      font-size: 2rem;
    }
    
    .oval-image-container {
      height: 250px;
    }
    
    .oval-1 {
      width: 200px;
      height: 280px;
    }
    
    .oval-2 {
      width: 220px;
      height: 220px;
    }
    
    .design-image-container {
      width: 200px;
      height: 200px;
    }
  }
  
  /* Rest of your existing CSS remains the same */
  .main-content {
    padding: 3rem 0;
  }
  
  .section-title {
    color: #d9534f;
    font-weight: bold;
    margin-bottom: 1.5rem;
    position: relative;
    display: inline-block;
  }
  
  .section-title::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 50px;
    height: 3px;
    background-color: #d9534f;
  }
  
  .stat-box {
    background-color: #f8f9fa;
    padding: 2rem;
    border-radius: 8px;
    height: 100%;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    border-top: 4px solid #d9534f;
  }
  
  .stat-box h4 {
    color: #d9534f;
    font-weight: bold;
  }

  /* AboutUs.css */
/* General Styles */
.about-us-page {
  font-family: 'Arial', sans-serif;
  color: #333;
  overflow-x: hidden;
}

/* Hero Section - Fixed background image */
.hero-section {
  height: 10vh;
  min-height: 200px;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.hero-content {
  position: relative;
  z-index: 1;
  text-align: center;
  color: white;
  padding: 0 20px;
}

.hero-content h1 {
  font-size: 4rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* Redesigned Design Section with Ovals */
.design-section-wrapper {
  margin: 5rem 0;
  align-items: center;
}

.oval-image-container {
  position: relative;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.oval-parent {
  position: relative;
  width: 100%;
  height: 100%;
}

.oval-shape {
  position: absolute;
  border-radius: 50%;
  background: linear-gradient(45deg, #d9534f, #f0ad4e);
  filter: blur(1px);
  opacity: 0.7;
}

.oval-1 {
  width: 300px;
  height: 400px;
  top: 0;
  left: 50px;
  animation: float 6s ease-in-out infinite;
}

.oval-2 {
  width: 350px;
  height: 350px;
  bottom: 0;
  right: 50px;
  animation: float 8s ease-in-out infinite reverse;
}

.design-image-container {
  position: relative;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  overflow: hidden;
  border: 5px solid white;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.design-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.design-content {
  padding: 2rem;
}

.design-title {
  font-size: 2.5rem;
  color: #d9534f;
  margin-bottom: 1.5rem;
  position: relative;
  display: inline-block;
}

.design-title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 50px;
  height: 3px;
  background-color: #d9534f;
}

.design-text {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
}

/* Our Work Section Styles */
.our-work-section {
  padding: 4rem 0;
  background-color: #f9f9f9;
  position: relative;
  overflow: hidden;
}

.our-work-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background: linear-gradient(90deg, #d9534f, #000);
}

.objectives-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 2.5rem;
  margin-top: 2rem;
  width: 100%;
}

.objective-card {
  background: white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border-top: 5px solid #d9534f;
  margin: 0;
}

.objective-header {
  background: #000;
  color: white;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
}

.objective-number {
  background: #d9534f;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
  box-shadow: 0 0 0 5px rgba(217, 83, 79, 0.3);
}

.objective-content {
  padding: 1.5rem;
}

.objective-content h4 {
  color: #d9534f;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.objective-content ul {
  padding-left: 1.5rem;
}

.objective-content ul li {
  margin-bottom: 0.5rem;
}

/* Program Schedule Section */
.program-schedule-section {
  padding: 4rem 0;
  background-color: #fff;
  position: relative;
}

.program-schedule-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background: linear-gradient(90deg, #000, #d9534f);
}

.pdf-button {
  background: #d9534f;
  border: none;
  padding: 1rem 2rem;
  font-weight: bold;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  box-shadow: 0 5px 15px rgba(217, 83, 79, 0.4);
}

.pdf-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: #000;
  transition: all 0.3s ease;
  z-index: -1;
}

.pdf-button:hover::before {
  left: 0;
}

.pdf-container {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
}

.pdf-iframe {
  border: none;
}

/* Main Content */
.main-content {
  padding: 3rem 0;
}

.section-title {
  color: #d9534f;
  font-weight: bold;
  margin-bottom: 1.5rem;
  position: relative;
  display: inline-block;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 50px;
  height: 3px;
  background-color: #d9534f;
}

.stat-box {
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 8px;
  height: 100%;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  border-top: 4px solid #d9534f;
}

.stat-box h4 {
  color: #d9534f;
  font-weight: bold;
}

/* Animation */
@keyframes float {
  0% {
      transform: translateY(0) rotate(0deg);
  }
  50% {
      transform: translateY(-20px) rotate(5deg);
  }
  100% {
      transform: translateY(0) rotate(0deg);
  }
}

/* Responsive Adjustments */
@media (min-width: 1400px) {
  .objectives-container {
      grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
      gap: 3rem;
  }
  
  .our-work-section .container {
      max-width: 1400px;
  }
}

@media (max-width: 1200px) {
  .objectives-container {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 2rem;
  }
}

@media (max-width: 992px) {
  .design-section-wrapper {
      flex-direction: column;
  }
  
  .oval-image-container {
      height: 300px;
      margin-bottom: 2rem;
  }
  
  .design-content {
      text-align: center;
  }
  
  .design-title::after {
      left: 50%;
      transform: translateX(-50%);
  }
}

@media (max-width: 768px) {
  .hero-content h1 {
      font-size: 2.5rem;
  }
  
  .design-title {
      font-size: 2rem;
  }
  
  .oval-1 {
      width: 250px;
      height: 350px;
      left: 20px;
  }
  
  .oval-2 {
      width: 300px;
      height: 300px;
  }
  
  .design-image-container {
      width: 250px;
      height: 250px;
  }

  .objectives-container {
      grid-template-columns: 1fr;
      gap: 1.5rem;
  }
  
  .objective-card {
      margin: 0 0.5rem;
  }
  
  .pdf-button {
      width: 100%;
      padding: 1rem;
  }
}

@media (max-width: 576px) {
  .hero-section {
      height: 50vh;
      min-height: 300px;
  }
  
  .hero-content h1 {
      font-size: 2rem;
  }
  
  .oval-image-container {
      height: 250px;
  }
  
  .oval-1 {
      width: 200px;
      height: 280px;
  }
  
  .oval-2 {
      width: 220px;
      height: 220px;
  }
  
  .design-image-container {
      width: 200px;
      height: 200px;
  }
}