.donate-section {
    position: relative;
    overflow: hidden;
  }
  
  /* Animated circles in background */
  .donate-section::before {
    content: '';
    position: absolute;
    top: -50px;
    right: -50px;
    width: 200px;
    height: 200px;
    background-color: rgba(220, 53, 69, 0.1);
    border-radius: 50%;
    z-index: 0;
  }
  
  .donate-section::after {
    content: '';
    position: absolute;
    bottom: -100px;
    left: -100px;
    width: 300px;
    height: 300px;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    z-index: 0;
  }
  
  /* Card hover effects */
  .card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    z-index: 1;
  }
  
  .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1) !important;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .donate-section::before,
    .donate-section::after {
      display: none;
    }
    
    .display-4 {
      font-size: 2.5rem;
    }
  }