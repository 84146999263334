/* Custom styles for How We Do It section */
#how-we-do-it .card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-radius: 12px;
  }
  
  #how-we-do-it .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
  
  #how-we-do-it .carousel {
    border-radius: 12px;
    overflow: hidden;
  }
  
  #how-we-do-it .btn-group .btn {
    border-radius: 8px;
    margin: 0 5px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    #how-we-do-it .btn-group {
      flex-direction: column;
      width: 100%;
    }
    
    #how-we-do-it .btn-group .btn {
      margin: 5px 0;
      width: 100%;
    }
  }