.contact-section {
    position: relative;
    overflow: hidden;
  }
  
  .card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1) !important;
  }
  
  .rounded-4 {
    border-radius: 1rem !important;
  }
  
  /* Animation for form elements */
  .form-control {
    transition: all 0.3s ease;
    border: 1px solid #ced4da;
  }
  
  .form-control:focus {
    border-color: #d62d20;
    box-shadow: 0 0 0 0.25rem rgba(214, 45, 32, 0.25);
  }
  
  /* Responsive adjustments */
  @media (max-width: 992px) {
    .contact-section .row {
      flex-direction: column;
    }
    
    .contact-section .col-lg-5, 
    .contact-section .col-lg-7 {
      max-width: 100%;
    }
  }
  
  /* Decorative shapes */
  .oval-shape, .circle-shape {
    position: absolute;
    z-index: 0;
  }
  
  /* Ensure content stays above decorative elements */
  .card, .form-control, h1, p {
    position: relative;
    z-index: 1;
  }