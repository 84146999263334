/* Enhanced Home.css */
.hero-container {
    position: relative;
    padding: 20px;
    height: auto;
    min-height: 600px;
    overflow: hidden;
  }
  
  .hero-image-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    will-change: transform; /* Improves animation performance */
  }
  
  .hero-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    filter: brightness(0.8);
    transform: translateZ(0); /* Fixes blurriness in some browsers */
    backface-visibility: hidden; /* Improves rendering */
    image-rendering: -webkit-optimize-contrast; /* Crisper image */
  }
  
  .hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
   
  }
  
  .hero-gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0.3) 50%,
      rgba(0, 0, 0, 0.1) 100%
    );
  }
  
  .hero-content {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 2;
    padding-top: 100px;
    padding-bottom: 80px;
  }
  
  .hero-subtitle {
    font-size: 1.2rem;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.9);
  }
  
  .hero-title {
    font-size: 3rem;
    font-weight: 800;
    letter-spacing: 1px;
    position: relative;
    display: inline-block;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    margin-bottom: 1.5rem !important;
  }
  
  .hero-title::after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 0;
    width: 80px;
    height: 5px;
    background: rgb(236, 61, 61);
    border-radius: 3px;
  }
  
  .hero-headline {
    font-size: 2.2rem;
    font-weight: 600;
    margin-bottom: 2rem !important;
    line-height: 1.3;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  }
  
  .hero-text {
    font-size: 1.2rem;
    line-height: 1.8;
    max-width: 600px;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 3rem !important;
  }
  
  .hero-button {
    background: linear-gradient(135deg, rgb(243, 66, 66) 0%, rgb(238, 59, 59) 100%);
    border: none;
    border-radius: 4px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    position: relative;
    overflow: hidden;
    transition: all 0.3s;
    box-shadow: 0 4px 15px rgba(255, 0, 0, 0.3);
    padding: 1.5rem 2.5rem !important;
  }
  
  .hero-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 20px rgba(255, 0, 0, 0.4);
  }
  
  .button-text {
    font-size: 1.3rem;
    position: relative;
    z-index: 2;
  }
  
  .button-subtext {
    font-size: 0.9rem;
    font-weight: 400;
    letter-spacing: 1px;
    position: relative;
    z-index: 2;
  }
  
  .button-hover-effect {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #c00000 0%, #ff2a2a 100%);
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
  }
  
  .hero-button:hover .button-hover-effect {
    opacity: 1;
  }
  
  .stats-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .stats-box {
    background: rgba(0, 0, 0, 0.6);
    border-left: 5px solid #ff2a2a;
    backdrop-filter: blur(5px);
    border-radius: 8px;
    padding: 2rem !important;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
  
  .stat-item {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .stat-item:last-child {
    margin-bottom: 0;
  }
  
  .stat-number {
    font-size: 3.5rem;
    font-weight: 800;
    line-height: 1;
    margin-bottom: 0.5rem;
    background: linear-gradient(to right, #ff2a2a, #ff6b6b);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .stat-label {
    font-size: 1.1rem;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.9);
  }
  
  .progress-bar-container {
    width: 100%;
    height: 6px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 3px;
    margin-top: 0.5rem;
    overflow: hidden;
  }
  
  .progress-bar-fill {
    height: 100%;
    background: linear-gradient(to right, #ff2a2a, #ff6b6b);
    border-radius: 3px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 1200px) {
    .hero-title1 {
      font-size: 2.5rem;
    }
    
    .hero-headline1 {
      font-size: 1.8rem;
    }
  }
  
  @media (max-width: 992px) {
    .hero-content {
      padding-top: 120px;
    }
    
    .stats-container {
      margin-top: 50px;
      justify-content: flex-start;
    }
    
    .stats-box {
      width: 100%;
    }
  }
  
  @media (max-width: 768px) {
    .hero-title {
      font-size: 2rem;
    }
    
    .hero-headline1 {
      font-size: 1.5rem;
    }
    
    .hero-text1 {
      font-size: 1rem;
    }
    
    .stat-number {
      font-size: 2.5rem;
    }
    
    .hero-button1 {
      padding: 1rem 1.5rem !important;
    }
    
    .button-text {
      font-size: 1.1rem;
    }
  }
  
  @media (max-width: 576px) {
    .hero-title1 {
      font-size: 1.8rem;
    }
    
    .hero-subtitle1 {
      font-size: 1rem;
    }
  }
   




.mission-page {

  color: #333;
  background-color: #fff;
  padding-top: 60px;
  position: relative;
}

/* Logo Tag */
.logo-tag {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  background: white;
  padding: 10px 20px;
  border-radius: 30px;
  box-shadow: 0 4px 15px rgba(0,0,0,0.1);
}

.logo-img {
  height: 40px;
  width: auto;
}

/* Mission Container */
.mission-container {
  padding: 40px 15px;
  max-width: 1200px;
}

.mission-content {
  padding: 40px;
}

.mission-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #d10000;
  margin-bottom: 20px;
  position: relative;
}

.mission-divider {
  height: 3px;
  width: 80px;
  background: #d10000;
  margin-bottom: 30px;
}

.mission-text {
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 25px;
  color: #333;
}

.highlight-box {
  background: rgba(209, 0, 0, 0.05);
  border-left: 4px solid #d10000;
  padding: 20px;
  margin: 30px 0;
}

.highlight-text {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #333;
  font-style: italic;
}

.program-title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #d10000;
  margin-top: 30px;
}

.objectives-list {
  font-size: 1.1rem;
  line-height: 1.8;
  margin-left: 20px;
}

.objectives-list li {
  margin-bottom: 10px;
  color: #333;
}



/* Call to Action */
.mission-cta {
  margin-top: 40px;
}

.cta-button {
  background: #d10000;
  color: white;
  border: none;
  padding: 18px 45px;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0 4px 15px rgba(209, 0, 0, 0.3);
  position: relative;
  overflow: hidden;
}

.cta-button:hover {
  background: #b00000;
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(209, 0, 0, 0.4);
}

.cta-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent);
  transition: all 0.5s;
}

.cta-button:hover::after {
  left: 100%;
}

/* Responsive Design */
@media (max-width: 992px) {
  .mission-content {
    padding: 20px;
  }
  
  .mission-title {
    font-size: 2rem;
  }
  
  .image-gallery-container {
    min-height: 400px;
  }
}

@media (max-width: 768px) {
  .mission-container {
    flex-direction: column;
  }
  
  .mission-content, .image-gallery-container {
    width: 100%;
    padding: 20px;
  }
  
  .image-gallery {
    height: 400px;
    margin-top: 40px;
  }
  
  .image-wrapper {
    width: 80%;
  }
}

@media (max-width: 576px) {
  .mission-title {
    font-size: 1.8rem;
  }
  
  .mission-text, .highlight-text {
    font-size: 1rem;
  }
  
  .cta-button {
    padding: 12px 30px;
    font-size: 1rem;
  }
  
  .logo-img {
    height: 30px;
  }
}
  /* Add this to your CSS file */
.donation-cta {
    background-color: hwb(0 0% 100%);
    position: relative;
    overflow: hidden;
  }
  
  .donation-cta::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(78, 84, 200, 0.03) 0%, rgba(143, 148, 251, 0.03) 100%);
    z-index: 0;
  }
  
  .donation-cta .btn {
    position: relative;
    overflow: hidden;
  }
  
  .donation-cta .btn::after {
    content: '';
    position: absolute;
    top: -50%;
    left: -60%;
    width: 200%;
    height: 200%;
    background: rgba(255, 255, 255, 0.2);
    transform: rotate(30deg);
    transition: all 0.3s ease;
  }
  
  .donation-cta .btn:hover::after {
    left: 100%;
  }
  
  @media (max-width: 992px) {
    .donation-cta {
      text-align: center;
    }
    
    .donation-cta .btn {
      display: block;
      margin: 0 auto;
    }
  }



  /* Home.css */

/* Base Styles */
.mission-page {
  position: relative;
  overflow-x: hidden;
  padding-top: 20px;
}

.logo-tag {
  text-align: center;
  margin-bottom: 30px;
}

.logo-img {
  max-width: 150px;
  height: auto;
}

.mission-container {
  padding: 0 15px;
}

/* Mission Content */
.mission-content {
  padding: 20px;
  margin-bottom: 30px;
}

.mission-title {
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 15px;
  font-weight: 700;
}

.mission-divider {
  height: 4px;
  width: 80px;
  background: #dc3545;
  margin-bottom: 20px;
}

.mission-text {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 20px;
}

.highlight-box {
  background-color: #f8f9fa;
  border-left: 4px solid #dc3545;
  padding: 15px;
  margin: 25px 0;
  border-radius: 0 4px 4px 0;
}

.highlight-text {
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
  margin: 0;
}

.objective-section {
  margin-top: 40px;
}

.program-title {
  font-size: 1.3rem;
  color: #2c3e50;
  margin: 25px 0 15px;
}

.objectives-list {
  padding-left: 20px;
}

.objectives-list li {
  margin-bottom: 10px;
  line-height: 1.5;
}

/* Image Gallery */
.image-gallery-container {
  padding: 15px;
}

.image-gallery {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.image-wrapper {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.gallery-image {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.2);
}

/* CTA Button */
.mission-cta {
  margin: 40px 0;
  text-align: center;
}

.cta-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(220, 53, 69, 0.3);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.cta-button:hover {
  background-color: #c82333;
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(220, 53, 69, 0.4);
}

/* Donation CTA Section */
.donation-cta {
  position: relative;
  padding: 40px 0;
  z-index: 1;
}

/* Responsive Styles */
@media (max-width: 1199.98px) {
  .mission-title {
    font-size: 1.8rem;
  }
}

@media (max-width: 991.98px) {
  .mission-content {
    padding: 15px;
  }
  
  .image-gallery {
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .image-wrapper {
    flex: 1 1 calc(50% - 10px);
    min-width: calc(50% - 10px);
  }
}

@media (max-width: 767.98px) {
  .logo-img {
    max-width: 120px;
  }
  
  .mission-title {
    font-size: 1.6rem;
  }
  
  .program-title {
    font-size: 1.2rem;
  }
  
  .mission-text, .highlight-text {
    font-size: 0.95rem;
  }
  
  .cta-button {
    padding: 12px 25px;
    font-size: 0.9rem;
  }
  
  /* Stack columns on mobile */
  .image-gallery-container {
    order: -1;
    margin-bottom: 30px;
  }
  
  .image-gallery {
    flex-direction: column;
  }
  
  .image-wrapper {
    flex: 1 1 100%;
    min-width: 100%;
  }
  
  /* Donation section adjustments */
  .donation-cta .display-4 {
    font-size: 2rem;
  }
  
  .donation-cta .btn {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
}

@media (max-width: 575.98px) {
  .mission-page {
    padding-top: 10px;
  }
  
  .logo-tag {
    margin-bottom: 20px;
  }
  
  .mission-title {
    font-size: 1.4rem;
    margin-bottom: 10px;
  }
  
  .mission-divider {
    height: 3px;
    width: 60px;
    margin-bottom: 15px;
  }
  
  .highlight-box {
    padding: 10px;
    margin: 15px 0;
  }
  
  .objective-section {
    margin-top: 30px;
  }
  
  .program-title {
    margin: 20px 0 10px;
  }
  
  .mission-cta {
    margin: 30px 0;
  }
  
  /* Ensure text doesn't overflow on very small screens */
  .mission-text, .highlight-text, .objectives-list li {
    word-break: break-word;
    hyphens: auto;
  }
  
  /* Hide decorative elements on very small screens */
  .donation-cta .position-absolute.bg-danger,
  .donation-cta .position-absolute.bg-dark {
    display: none;
  }
}

/* Animation Enhancements */
.mission-content, .image-gallery {
  will-change: transform, opacity;
}

/* Prevent overlapping in donation section */
.donation-cta .position-absolute {
  display: none; /* Removing animated circles on mobile */
}

@media (min-width: 768px) {
  .donation-cta .position-absolute {
    display: block; /* Show animated circles on larger screens */
  }
}

/* Ensure images don't cause horizontal overflow */
img {
  max-width: 100%;
  height: auto;
}
